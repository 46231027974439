<template>
    <!-- Begin Footer Area -->
    <footer class="footer-style-4" data-bg-image="" :style="{backgroundImage: `url(${ footerBg })`}">
        <!-- <div class="footer-upper-top"> -->
        <!--     <div class="container"> -->
        <!--         <div class="row"> -->
        <!--             <div class="col-lg-12"> -->
        <!--                 <div class="footer-upper-top-item"> -->
        <!--                     <router-link to="/" class="footer-logo"> -->
        <!--                         <img src="images/logo/logo.png" alt="Logo"> -->
        <!--                     </router-link> -->
        <!--                     <div class="footer-social-link"> -->
        <!--                         <ul> -->
        <!--                             <li v-for="(social, index) in socials" :key="index"> -->
        <!--                                 <a :href="social.link"> -->
        <!--                                     <i v-bind:class="social.iconName"></i> -->
        <!--                                 </a> -->
        <!--                             </li> -->
        <!--                         </ul> -->
        <!--                     </div> -->
        <!--                     <div class="footer-btn-wrap"> -->
        <!--                         <span class="title">Download app:</span> -->
        <!--                         <router-link to="/contact"> -->
        <!--                             <img src="images/button/8-1-179x62.png" alt="Button"> -->
        <!--                         </router-link> -->
        <!--                         <router-link to="/contact"> -->
        <!--                             <img src="images/button/8-2-176x59.png" alt="Button"> -->
        <!--                         </router-link> -->
        <!--                     </div> -->
        <!--                 </div> -->
        <!--             </div> -->
        <!--         </div> -->
        <!--     </div> -->
        <!-- </div> -->
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 d-none d-lg-block">
                        <div class="footer-item mt-0">
                            <div class="footer-list contact-list">
                                <h2 class="title">Contact Us</h2>
                                <ul>
                                    <li>
                                        <i class="icofont-ui-call"></i>
                                        <a href="tel://02-407-1023">(02)407-1023</a>
                                    </li>
                                    <li>
                                        <i class="icofont-envelope-open"></i>
                                        <a href="mailto://contact@yadanarbonbank.com">contact@yadanarbonbank.com</a>
                                    </li>
                                    <li>
                                        <i class="icofont-google-map"></i>
                                        <span>26st, Bet 84&85, Mandalay, Myanmar</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 d-block d-lg-none">
                        <div class="footer-item-wrap row">
                            <div class="col-sm-6">
                                <div class="footer-item mt-0">
                                    <div class="footer-list contact-list">
                                        <h2 class="title">Contact Us</h2>
                                 <ul>
                                    <li>
                                        <i class="icofont-ui-call"></i>
                                        <a href="tel://02-407-1023">(02)407-1023</a>
                                    </li>
                                    <li>
                                        <i class="icofont-envelope-open"></i>
                                        <a href="mailto://contact@yadanarbonbank.com">contact@yadanarbonbank.com</a>
                                    </li>
                                    <li>
                                        <i class="icofont-google-map"></i>
                                        <span>26st, Bet 84&85, Mandalay, Myanmar</span>
                                    </li>
                                </ul>
                                   </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6">
                                <div class="footer-item mt-sm-0">
                                    <div class="footer-list">
                                        <h2 class="title">Company</h2>
                                        <ul>
                                            <li><router-link to="/faq">FAQ</router-link></li>
                                            <li><router-link to="/contact">Contact Us</router-link></li>
                                            <li><router-link to="/contact">Term & Condition</router-link></li>
                                            <li><router-link to="/contact">Privacy & Policy</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="footer-item-wrap row">
                            <!-- <div class="col-lg-3 d-none d-lg-block">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">Company</h2>
                                        <ul>
                                            <li><router-link to="/blog">Blog</router-link></li>
                                            <li><router-link to="/contact">Contact Us</router-link></li>
                                            <li><router-link to="/contact">Term & Condition</router-link></li>
                                            <li><router-link to="/contact">Privacy & Policy</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-3 col-sm-4">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">Resources</h2>
                                        <ul>
                                            <li><router-link to="/contact">Affiliate</router-link></li>
                                            <li><router-link to="/contact">Blog</router-link></li>
                                            <li><router-link to="/contact">Help & Support</router-link></li>
                                            <li><router-link to="/contact">Careers</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-3 col-sm-4">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">Legal</h2>
                                        <ul>
                                            <li><router-link to="/contact">Affiliate</router-link></li>
                                            <li><router-link to="/contact">Blog</router-link></li>
                                            <li><router-link to="/contact">Help & Support</router-link></li>
                                            <li><router-link to="/contact">Careers</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-3 col-sm-4">
                                <div class="footer-item">
                                    <div class="footer-list">
                                        <h2 class="title">About Company</h2>
                                        <ul>
                                            <li><router-link to="/contact">Contact Us</router-link></li>
                                            <li><router-link to="/contact">FAQ</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-bottom-item">
                            <div class="footer-copyright white-text">
                                <span>© 2022 Yadanarbon Bank</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End Here -->

    <!-- Begin Scroll To Top -->
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="footer-scroll-top scroll-to-top style-4 show border-0">
            <i class="icofont-arrow-up"></i>
        </button>
    </back-to-top>
    <!-- Scroll To Top End Here -->
</template>

<script>
export default {
  data () {
    return {
      footerBg: 'images/footer/bg/4-1-1920x803.png',
      socials: [
        {
          link: 'https://www.facebook.com',
          iconName: 'icofont-facebook'
        },
        {
          link: 'https://www.skype.com',
          iconName: 'icofont-skype'
        },
        {
          link: 'https://www.twitter.com',
          iconName: 'icofont-twitter'
        }
      ]
    }
  }
}
</script>
